<template>
  <div id="trip-edit">
    <div class="header">
      <van-row gutter="10">
        <van-col span="10" class="header-title" @click="onClickLeft">
          <svg-icon
            :data_iconName="'back-active'"
            :className="'back_icon'"
          />行程添加
        </van-col>
        <van-col
          span="14"
          class="header-title"
          style="text-align: right"
          @click="onDoAddClick"
        >
          完成
        </van-col>
      </van-row>
    </div>
    <div class="trip-item-body">
      <div class="item-body-title">行程信息</div>
      <van-row class="input-container">
        <van-col span="12">
          <div class="input-text">行程名称</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            type="text"
            v-model="travel_plan.plan_name"
            placeholder="请输入行程名称"
          />
        </van-col>
      </van-row>
      <van-row class="input-container">
        <van-col span="12">
          <div class="input-text">开始地点</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            type="text"
            v-model="travel_plan.start_addr"
            placeholder="请输入开始地点"
          />
        </van-col>
      </van-row>
      <van-row class="input-container">
        <van-col span="12">
          <div class="input-text">结束地点</div>
        </van-col>
        <van-col span="12" class="input-item">
          <input
            type="text"
            v-model="travel_plan.end_addr"
            placeholder="请输入结束地点"
          />
        </van-col>
      </van-row>

      <div class="xianlu-container">
        <!-- <div class="item-body-title">行程介绍</div>
        <van-row class="description-container">
          <van-col span="24">
            <textarea
              class="description-text-area"
              v-model="travel_plan.description"
              placeholder="请输入行程介绍"
              name=""
              id=""
              cols="20"
              rows="5"
            ></textarea>
          </van-col>
        </van-row> -->
        <div class="item-body-title">封面图</div>
        <van-uploader
          max-count="1"
          v-model="fileList"
          :before-delete="onDeleteImageUrl"
          preview-full-image
          accept="image/*"
          :after-read="fnUploadRequest"
        />
        <div class="item-body-title">行程线路</div>
        <div class="trip-step">
          <van-steps
            direction="vertical"
            active-color="#969799"
            active-icon="star"
            :active="-1"
          >
            <van-step
              v-for="(item, index) in travel_plan.travel_plan_nodes_attributes"
              :key="index"
            >
              <h3>
                <input
                  type="text"
                  v-model="item.node_name"
                  placeholder="请输入线路名称"
                />
              </h3>
              <p>
                <input
                  type="text"
                  v-model="item.node_description"
                  placeholder="请输入线路描述"
                />
              </p>
              <van-icon
                name="delete"
                v-if="index !== 0"
                @click="beforeClose(index)"
              />
            </van-step>
          </van-steps>
          <div @click="onAddClick">
            <svg-icon :data_iconName="'add-white'" :className="'add_icon'" />
          </div>
        </div>
      </div>

      <div class="btn" @click="onDoAddClick">完成</div>
    </div>
    <vue-canvas-poster
      :widthPixels="1000"
      :painting="painting"
      @success="success"
      @fail="fail"
    ></vue-canvas-poster>
  </div>
</template>

<script>
import { Toast } from "vant";
import { Dialog } from "vant";
import oss from "@/utils/oss";
export default {
  name: "trip-edit",
  components: {},
  data() {
    return {
      list: [],
      fileList: [],
      active: 1,
      show: false,
      item: {},
      loading: true,
      finished: false,
      refreshing: false,
      query: {
        page: 1,
        plan_name: "",
      },
      travel_plan: {
        plan_name: "",
        description: "",
        end_addr: "",
        start_addr: "",
        status: "1",
        image_url: "",
        travel_plan_nodes_attributes: [
          {
            sort_id: 1,
            node_name: "",
            node_description: "",
          },
        ],
      },
      painting: {
        width: `${document.documentElement.clientWidth}px`,
        height: `${document.documentElement.clientWidth * 1.777}px`,
        background: "#333333",
        views: [
          {
            type: "image",
            url: "https://orchid-server.oss-cn-hangzhou.aliyuncs.com/image/20211027/20211027235049_zo6g.jpeg",
            css: {
              top: "20px",
              left: "36px",
              borderRadius: "40px",
              width: "80px",
              height: "80px",
            },
          },
        ],
      },
    };
  },
  methods: {
    onClickLeft() {
      this.$router.go(-1);
    },
    onConfirm() {},
    onAddClick() {
      const attributes_length =
        this.travel_plan.travel_plan_nodes_attributes.length;
      const next_sort_id =
        this.travel_plan.travel_plan_nodes_attributes[attributes_length - 1]
          .sort_id + 1;

      this.travel_plan.travel_plan_nodes_attributes.push({
        sort_id: next_sort_id,
        node_name: "",
        node_description: "",
      });
    },

    beforeClose(index) {
      const _self = this;
      Dialog.confirm({
        title: "温馨提醒",
        message: "确认删除吗？",
      })
        .then(() => {
          // on confirm
          _self.travel_plan.travel_plan_nodes_attributes.splice(index, 1);
        })
        .catch(() => {
          // on cancel
        });
    },
    async onDoAddClick() {
      if (this.travel_plan.plan_name == "") {
        Toast("请填写行程名称");
        return false;
      }
      if (this.travel_plan.plan_name.length > 14) {
        Toast("行程名称长度不能超过14位");
        return false;
      }
      // if (this.travel_plan.description == "") {
      //   Toast("请填写行程介绍");
      //   return false;
      // }
      // if (this.travel_plan.image_url == "") {
      //   Toast("请上传封面图");
      //   return false;
      // }
      if (this.travel_plan.end_addr == "") {
        Toast("请填写结束地点");
        return false;
      }
      if (this.travel_plan.start_addr == "") {
        Toast("请填写开始地点");
        return false;
      }
      var flag = true;
      this.travel_plan.travel_plan_nodes_attributes.forEach((value) => {
        if (value.node_name == "" || value.node_description == "") {
          // Toast("请填写完整在提交");
          flag = false;
          return false;
        } else {
          return true;
        }
      });

      if (!flag) {
        Toast("请填写完整再提交");
        return false;
      }

      const res = await this.$apis.post_travel_plans({
        travel_plan: this.travel_plan,
      });
      if (res.status == 200) {
        Toast("添加成功");
        this.$router.go(-1);
      }
    },
    onItemClick() {
      this.$router.push("/trip/item");
    },
    onEditClick() {
      this.$router.push({ path: "/trip/edit" });
    },
    onShowClick() {
      //   this.item = item;
      this.show = true;
    },
    async get_travel_plans() {
      const res = await this.$apis.get_travel_plans(this.query);
      if (res.data.length == 25) {
        this.query.page++;

        this.loading = false;
        this.refreshing = false;
      } else {
        this.finished = true;
        this.loading = false;
        this.refreshing = false;
      }
      for (var i = 0; i < res.data.length; i++) {
        this.list.push(res.data[i]);
      }
    },
    onRefresh() {
      // 清空列表数据
      this.list = [];
      this.finished = false;
      this.query.page = 1;
      this.loading = true;
      this.get_travel_plans();
    },
    onDeleteImageUrl() {
      this.travel_plan.image_url = "";
      this.fileList = [];
    },
    fnUploadRequest(option) {
      option.status = "uploading";
      option.message = "上传中...";
      const _self = this;
      // Toast.loading({
      //   message: "上传中...",
      //   forbidClick: true,
      // });
      oss.ossUploadFile(option).then((res) => {
        _self.travel_plan.image_url = res.fileUrl[0].split("?")[0];
        option.status = "success";
        // option.message = '上传中...';
        _self.fileList = [
          {
            url: res.fileUrl[0].split("?")[0],
          },
        ];
        Toast.clear();
      });
    },
    success(src) {
      // console.log(src);
    },
    fail(err) {
      console.log("fail", err);
    },
  },
  created() {
    // this.onRefresh();
  },
};
</script>

<style scoped>
#trip-edit {
  background: #f3f3f3;
  height: 100vh;
  overflow: hidden;
  padding-top: 10vh;
}
.header {
  height: 10vh;
  line-height: 10vh;
  padding: 0 0.3rem;
}
.header-title {
  color: #333;
  font-size: 0.4rem;
  font-weight: bold;
  vertical-align: middle;
}
.search {
  position: relative;
}
.search-input {
  border-radius: 14.5px;
  border-radius: 14.5px;
  height: 0.6rem;
  width: 100%;
  background: rgba(216, 216, 216, 0.21);
  padding-right: 1rem;
  padding-left: 0.3rem;
  font-size: 0.4rem;
  color: #fff;
  vertical-align: middle;
  line-height: 0.6rem;
}
.search-input-icon {
  position: absolute;
  right: 0.2rem;
  top: 50%;
  transform: translateY(-0.1rem);
  font-size: 0.4rem;
}
.trip-item-body {
  height: 85vh;
  width: 90%;
  margin: 0 auto;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-top: 2vh;
  padding: 0.4rem 0.4rem;
  overflow-y: scroll;
  padding-bottom: 6rem;
  position: relative;
  background-color: #fff;
}
.item-body-title {
  font-size: 0.3rem;
  color: #333333;
  font-weight: bold;
  margin-bottom: 20px;
}
.input-container {
  background: #eaeaea;
  border-radius: 24px;
  border-radius: 24px;
  height: 0.8rem;
  line-height: 0.8rem;
  padding: 0 0.4rem;
  margin-top: 0.3rem;
}
.input-text {
  text-align: left;
  font-size: 12px;
  color: #333333;
}
.input-item > input {
  background: #eaeaea;
  width: 70%;
  height: 0.8rem;
  line-height: 0.8rem;
  color: #333;
  font-size: 12px;
  float: right;
  text-align: left;
}
.input-item > input::placeholder {
  font-size: 12px;
  color: #b1b1b1;
  text-align: left;
}
.xianlu-container {
  margin-top: 0.4rem;
}
.trip-step {
  /* margin-top: 0.4rem;border-top:1px dashed #ebedf0;
    padding-top: .5rem; */
}
.add_icon {
  font-size: 0.5rem;
}
.trip-step input::placeholder {
  color: #999;
}
.description-container {
  margin-top: 20px;
}
.description-text-area {
  border-radius: 0.2rem;
  background: #eaeaea;
  width: 100%;
  padding: 0.3rem;
  font-size: 12px;
}
.btn {
  height: 0.8rem;
  text-align: center;
  color: #436ef3;
  background: #ecf0fe;
  line-height: 0.8rem;
  margin-top: 0.4rem;
  font-size: 0.3rem;
  border-radius: 5px;
}
</style>
